<template>
    <div class="map-view">
        <l-map :zoom="zoom" :center="center" style="height: 100%">
          <l-tile-layer :url="url"></l-tile-layer>
          <l-marker :lat-lng="coordinates" :icon="markerIcon"></l-marker>
        </l-map>
      </div>
</template>

<script>
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import "leaflet/dist/leaflet.css";

export default{
    name: "MapView",
  components: {
    LMap,
    LTileLayer,
    LMarker,
  },
  mounted(){

  },
  props: {
    zoom: {
        type: Number,
        default: 10
    },
    center: {
        type: Array,
        default: () => [17.4065, 78.4772] // ✅ Function for array default
    },
    coordinates: {
        type: Array,
        default: () => [17.4065, 78.4772] // ✅ Function for array default
    },
    url: {
        type: String,
        default: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
    }
},

  data(){
    return {
        isShowOnMap: false,
    //   center: [17.4065, 78.4772],
    //   url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    //   coordinates: [17.4065, 78.4772],
    //   zoom: 10,
    }
  }
}


</script>